import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaCalendarCheck, FaPhone } from "react-icons/fa"
import { MdPinDrop } from "react-icons/md"

export default () => (
  <Wrapper>
    <H2>
      <span>Informations</span> pratiques
    </H2>
    <hr />
    <Row>
      <Col1>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21002.235330161733!2d2.302042746736518!3d48.85288200559685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1a966571b85f101!2sEspace+M%C3%A9dical+Vauban!5e0!3m2!1sfr!2sus!4v1559670912011!5m2!1sfr!2sus"
          width="100%"
          height="370"
          frameborder="0"
          allowfullscreen
          title="Plan d'accès vers l'Espace Medical Vauban, Paris 7"
        />
      </Col1>
      <Col2>
        <p>
          Les <strong>mardi</strong> et <strong>vendredi</strong>, les
          consultations se déroulent à l'
          <Link to="/consultation/">Espace Médical Vauban</Link> (2A, Avenue de
          Ségur, 75007 Paris). Les <strong>lundi</strong> et{" "}
          <strong>vendredi</strong>, les consultations sont réalisées à la{" "}
          <Link to="/hospitalisation/">Clinique du sport</Link> (36, Boulevard
          Saint Marcel, 75005 Paris) .
        </p>
        <p>
          Des <Link to="/actus/video-consultation/">vidéo-consultations</Link>{" "}
          sont également proposées sur la plateforme Doctolib.
        </p>

        <p
          css={css`
            margin-top: 15px;
          `}
        >
          <strong>Comment prendre rendez-vous ?</strong>
        </p>

        <P>
          <a href="tel:+33153598809">
            <FaPhone /> Espace Médical Vauban<span> (Virginie)</span> : 01 53 59
            88 09
          </a>
        </P>
        <P>
          <a href="tel:+33153598809">
            <FaPhone /> Clinique du Sport <span>(Paula) </span>: 01 86 86 75 05
          </a>
        </P>
        <P>
          <a
            href="https://www.doctolib.fr/chirurgien-orthopediste/paris/philippe-roure"
            targer="_blank"
            rel="noreferrer noopener"
          >
            <FaCalendarCheck /> Prendre rendez-vous sur Doctolib
          </a>
        </P>

        <p
          css={css`
            line-height: 1.5em;
            font-style: italic;
            margin-top: 15px;
            @media (max-width: 678px) {
              font-size: 0.93em;
              text-align: center;
            }
          `}
        >
          Seule une partie de l'agenda est accessible sur Doctolib, appelez-nous
          pour connaitre l'ensemble des rendez-vous disponibles.
        </p>
        <Button to="/contact/">
          <MdPinDrop
            css={css`
              transform: translateY(2px);
            `}
          />{" "}
          Contact & Accès
        </Button>
      </Col2>
    </Row>
  </Wrapper>
)

const Wrapper = styled.section`
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 0.5em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`
const H2 = styled.h2`
  text-align: center;
  padding-top: 0.25em 0 0.5em;
  font-weight: 400;
  font-size: 2.4em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 2.15em;
  }
`

const Row = styled.div`
  display: flex;
  margin-top: 40px;
  @media (max-width: 678px) {
    display: block;
  }
`

const Col1 = styled.div`
  flex: 50%;
  padding-right: 15px;
  @media (max-width: 678px) {
    padding: 0;
    margin-bottom: 5px;
  }
`

const Col2 = styled.div`
  flex: 50%;
  padding-left: 15px;
  @media (max-width: 678px) {
    padding: 0;
  }
`

const P = styled.p`
  margin-bottom: 3px;
  a {
    border-bottom: none;
  }
  @media (max-width: 678px) {
    font-size: 0.93em;
    span {
      display: none;
    }
  }
`

const Button = styled(Link)`
  display: block;
  margin: 0 auto;
  width: 170px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 0.4em 0.8em 0.5em;
  background-color: #d69c31;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.94em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  :hover,
  :focus {
    color: white;
    background-color: rgba(44, 44, 44, 0.96);
    outline: none;
    transition: all 0.15s ease-in-out;
  }
`
